import { 
	defineStore
} from 'pinia'

import { 
	useStorage
} from '@vueuse/core'

	// types
import {
		ValueList,
		ValueListValue,
		ValueListStore
	} from '@/types'


export const store = defineStore('valueList', {
	// a function that returns a fresh state
	state: () => ({
		valueList: useStorage('valueList' ,{
			manufacturers: [{
				fullName: "",
				displayName: ""
			}]
        } as ValueListStore)
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {
		
	}
})