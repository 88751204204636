import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-slot": "text",
  class: "text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}