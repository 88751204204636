import EquipmentSearchMain from './EquipmentSearchMain.vue'

const config = {
	mainRoute: {
		name: "EquipmentSearch",
		path: "/",
		component: EquipmentSearchMain 
	}
}

export { config }
