import App from './App.vue'
import axios from 'axios'
import './assets/css/index.css'


import { // vue
	createApp
} from 'vue'

import { // pinia
	createPinia
} from 'pinia'

import { // router
	router
} from './router/index'

/**
 * Axios setup
 */
if(localStorage.sessionId == undefined) {
	localStorage.sessionId = Math.random().toString(36).substring(2)
}
const sessionId = localStorage.sessionId

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Session-Id'] = sessionId
axios.defaults.headers.common['Content-Type'] = 'application/json'


// import global components
/* Button */
import VButton from '@/components/Buttons/VButton.vue'
/* Dropdown */
import VDropdown from '@/components/Dropdowns/VDropdown.vue'
import VDropdownButton from '@/components/Dropdowns/VDropdownButton.vue'
import VDropdownMenu from '@/components/Dropdowns/VDropdownMenu.vue'
import VDropdownItem from '@/components/Dropdowns/VDropdownItem.vue'
/* Fieldset */
import VDescription from '@/components/Fieldsets/VDescription.vue'
import VErrorMessage from '@/components/Fieldsets/VErrorMessage.vue'
import VField from '@/components/Fieldsets/VField.vue'
import VFieldset from '@/components/Fieldsets/VFieldset.vue'
import VFieldGroup from '@/components/Fieldsets/VFieldGroup.vue'
import VLabel from '@/components/Fieldsets/VLabel.vue'
import VLegend from '@/components/Fieldsets/VLegend.vue'
/* Input */
import VDatalist from '@/components/Inputs/VDatalist.vue'
import VInput from '@/components/Inputs/VInput.vue'
import VInputGroup from '@/components/Inputs/VInputGroup.vue'
/* Select */
import VSelect from '@/components/Selects/VSelect.vue'
/* Text */
import VStrong from '@/components/Text/VStrong.vue'
import VText from '@/components/Text/VText.vue'
import VTextLink from '@/components/Text/VTextLink.vue'
const app = createApp(App)

// Register global components
/* Button */
app.component("VButton", VButton)
/* Dropdown */
app.component("VDropdown", VDropdown)
app.component("VDropdownButton", VDropdownButton)
app.component("VDropdownMenu", VDropdownMenu)
app.component("VDropdownItem", VDropdownItem)
/* Fieldset */
app.component("VDescription", VDescription)
app.component("VErrorMessage", VErrorMessage)
app.component("VField", VField)
app.component("VFieldset", VFieldset)
app.component("VFieldGroup", VFieldGroup)
app.component("VLabel", VLabel)
app.component("VLegend", VLegend)
/* Input */
app.component("VDatalist", VDatalist)
app.component("VInput", VInput)
app.component("VInputGroup", VInputGroup)
/* Select */
app.component("VSelect", VSelect)
/* Text */
app.component("VStrong", VStrong)
app.component("VText", VText)
app.component("VTextLink", VTextLink)

/* Pinia */
const pinia = createPinia()
app.use(pinia)

/* Router */
app.use(router)

app.mount('#app')